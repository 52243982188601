@import "../../../css/partials.meta";
@import "language-switcher.variables";

@mixin set-top($step: 1) {
	top: calc(
		#{$db-language-switcher---direction} * (100% * #{$step} + 1rem - 2px)
	);
}

.cmp-language-switcher {
	list-style: none;

	margin-right: to-rem($pxValue: 16);
	position: relative;

	&:hover,
	&:focus {
		li {
			&:not([aria-current], [aria-selected]) {
				visibility: visible;
			}

			&[aria-current="page"],
			&[aria-selected="true"] {
				color: $db-color-red-500;
			}
		}
	}

	li {
		background-color: #fdfdfd; // TODO: This would need to get replaced by the correct (semantic) color

		position: absolute;

		top: to-rem($pxValue: 26);

		.rea-header & {
			@include set-top(1);

			z-index: $z-index-rea-header-cmp-language-switcher-li; // * TODO: possibly rework variable naming

			// TODO: This is an enhancement for more languages, but obviously doesn't scale that nicely and would need some rework
			&:not([aria-current="page"], [aria-selected="true"])
				~ li:not([aria-current="page"], [aria-selected="true"]) {
				@include set-top(2);

				& ~ li:not([aria-current="page"], [aria-selected="true"]) {
					@include set-top(3);

					& ~ li:not([aria-current="page"], [aria-selected="true"]) {
						@include set-top(4);

						&
							~ li:not(
								[aria-current="page"],
								[aria-selected="true"]
							) {
							@include set-top(5);

							&
								~ li:not(
									[aria-current="page"],
									[aria-selected="true"]
								) {
								@include set-top(6);
							}
						}
					}
				}
			}
			@media screen and (width > $db-break-the-header-max-width) {
				top: 100%;
			}
		}

		.elm-link,
		.elm-button {
			color: inherit;
			text-decoration: none;
		}

		&:not([aria-current], [aria-selected]) {
			visibility: hidden;

			.elm-link,
			.elm-button {
				padding-block: 1rem;
				padding-right: to-rem($pxValue: 34);

				&:hover,
				&:focus {
					background-color: $db-color-cool-gray-200;
				}
			}
		}

		&[aria-current],
		&[aria-selected] {
			position: initial;

			.elm-link,
			.elm-button {
				& {
					@include icon(
						glyph(expand-more),
						24,
						"outline",
						"after",
						$partial: $partial
					);
				}

				&:hover {
					color: $db-color-red-500;
				}

				&::after {
					float: right;

					margin-left: to-rem($pxValue: 5);
				}
			}
		}

		.elm-link,
		.elm-button {
			display: inline-block;

			padding-left: to-rem($pxValue: 18);

			&:hover,
			&:focus {
				color: initial;
			}
		}

		.elm-button {
			line-height: 1.5;
			padding-right: 0;
			border-width: 0;
			padding-top: to-rem($pxValue: 9);
		}
	}
}
