@import "../../../css/partials.meta";
@import "chip.variables";

.elm-chip {
	& + label {
		--db-focus-outline-size: max(2px, 0.08em);

		align-items: center;
		border: solid 1px $db-color-cool-gray-400;
		border-radius: $chip---radius;
		display: inline-flex;
		font-weight: 500;

		// Link related styles
		font-size: to-rem($pxValue: 14);
		height: to-rem($pxValue: 28);
		justify-content: center;
		padding-left: to-rem($pxValue: 7);
		padding-right: to-rem($pxValue: 7);
		text-decoration: none;
		text-transform: capitalize;
		white-space: nowrap;

		// Icons
		&[data-icon],
		&[data-icon-before] {
			@include iconMeta(20);
		}

		&[data-icon-after] {
			@include iconMeta($size: 20, $position: "after");
		}
	}

	&:not(:disabled, :checked) {
		// States
		& + label:hover {
			background-color: $chip-hover--backgroundColor;
		}
	}

	&:checked {
		& + label {
			background-color: $chip-selected--backgroundColor;
			border-color: transparent;
			color: $chip-checked--color;

			//*** Variants ***
			&[data-variant="light"] {
				background-color: $db-color-cool-gray-200;
				color: $db-color-cool-gray-700;
				border: 1px solid $db-color-cool-gray-400;
			}

			&[data-variant="warning"] {
				background-color: $db-color-warning;
			}

			&[data-variant="error"] {
				background-color: $db-color-error;
			}

			&[data-variant="informative"] {
				background-color: $db-color-informative;
			}

			&[data-variant="success"] {
				background-color: $db-color-success;
			}

			// POIs
			&[data-variant="poi-essen-trinken"] {
				background-color: $db-color-food-drink;
				color: $db-color-cool-gray-700;
			}

			&[data-variant="poi-einkaufen"] {
				background-color: $db-color-shopping;
			}

			&[data-variant="poi-gesundheit"] {
				background-color: $db-color-health;
			}

			&[data-variant="poi-kunst-kultur"] {
				background-color: $db-color-arts-culture;
			}

			&[data-variant="poi-wissenswertes"] {
				background-color: $db-color-things-to-know;
			}

			&[data-variant="poi-freizeit"] {
				background-color: $db-color-leisure;
			}

			&[data-variant="poi-zivile-rel-einrichtungen"] {
				background-color: $db-color-community-facilities;
			}

			&[data-variant="poi-dienstleistungen"] {
				background-color: $db-color-services;
				color: $db-color-cool-gray-700;
			}

			&[data-variant="poi-db-services-einrichtung"] {
				background-color: $db-color-db-services-facilities;
			}

			&[data-variant="poi-wegeleitung"] {
				background-color: $db-color-guidance;
			}
		}
	}

	&:disabled {
		& + label {
			opacity: 0.5;
		}
	}

	&:focus-visible {
		& + label {
			outline: var(--db-focus-outline-size)
				var(--db-focus-outline-style, solid)
				var(--db-focus-outline-color, currentColor);
			outline-offset: var(
				--db-focus-outline-offset,
				var(--db-focus-outline-size)
			);
		}
	}

	&[type="checkbox"],
	&[type="radio"] {
		@include a11y-visually-hidden($partial: $partial);
	}
}
