@import "../../css/helpers/functions";
@import "form-elements.variables";

%form-element {
	--formElement---borderTopColor: var(
		--formElement---borderColor,
		transparent
	);
	--formElement---borderRightColor: var(
		--formElement---borderColor,
		transparent
	);
	--formElement---borderBottomColor: var(
		--formElement---borderColor,
		#{$form-elements-semitransparent-border-bottom-color}
	);
	--formElement---borderLeftColor: var(
		--formElement---borderColor,
		transparent
	);
	border-radius: 4px;

	caret-color: $db-color-red-500;

	color: $form-elements-color;

	// Description styles
	& ~ .description {
		color: $db-color-cool-gray-500;
		font-size: to-rem($pxValue: 14);

		padding: to-rem($pxValue: 6) to-rem($pxValue: 10);
	}
}

%form-label {
	&[data-label-hidden="true"] {
		@include a11y-visually-hidden($partial: $partial);
	}
}

// ### Style variations
// Semitransparent
%form-element-semitransparent {
	--formElement---borderColor: #{$form-elements-semitransparent-border-bottom-color};
	background-color: $form-elements-semitransparent-background-color;
	border-bottom: $form-elements---borderBottom;
	border-left: $form-elements---borderLeft;
	border-left-color: transparent;
	border-right: $form-elements---borderRight;
	border-right-color: transparent;
	border-top: $form-elements---borderTop;
	border-top-color: transparent;
}

// White
%form-element-white {
	--formElement---borderColor: #{$form-elements-white-border-bottom-color};
	background-color: $form-elements-white-background-color;
	border-bottom: $form-elements---borderBottom;
	border-left: $form-elements---borderLeft;
	border-left-color: transparent;
	border-right: $form-elements---borderRight;
	border-right-color: transparent;
	border-top: $form-elements---borderTop;
	border-top-color: transparent;
}

// Solid
%form-element-solid {
	--formElement---borderColor: #{$form-elements-solid-border-bottom-color};
	background-color: $form-elements-solid-background-color;
	border-bottom: $form-elements---borderBottom;
	border-left: $form-elements---borderLeft;
	border-left-color: transparent;
	border-right: $form-elements---borderRight;
	border-right-color: transparent;
	border-top: $form-elements---borderTop;
	border-top-color: transparent;
}

// Outline
%form-element-outline {
	--formElement---borderColor: #{$form-elements-outline-border-color};
	background-color: transparent;
	border-bottom: $form-elements---borderBottom;
	border-left: $form-elements---borderLeft;
	border-right: $form-elements---borderRight;
	border-top: $form-elements---borderTop;
}

// Validation
// We need to differentiate here because of any non-textual input elements like select, radio and checkbox, that we couldn't handle by :placeholder-shown
// TODO: maybe we could totally restructure this, work with a :not(select) instead, differentiate in between user-invalid and provide a fallback (https://github.com/db-ui/mono/pull/893), etc.
%form-element-validation {
	&:not([aria-invalid]) {
		&:user-valid {
			--formElement---borderColor: #{$db-color-green-600};
		}

		&:user-invalid {
			--formElement---borderColor: #{$db-color-red-500};
		}
	}
}
%form-element-validation-programmatic {
	&[aria-invalid="true"] {
		--formElement---borderColor: #{$db-color-red-500};
	}
}

// counter styling
%form-element-counter {
	&:has(+ .description) {
		float: right;
	}

	@supports (position-area: block-end span-inline-start) {
		&:not(:has(+ .description)) {
			position: absolute;
			// stylelint-disable-next-line property-no-unknown
			position-area: block-end span-inline-start;
		}
	}

	// Description styles
	// TODO: This probably still needs to get further changed to a general "hint" pattern or similar
	&,
	& + .description {
		color: $db-color-cool-gray-500;
		font-size: to-rem($pxValue: 14);
		padding: to-rem($pxValue: 6) to-rem($pxValue: 10);
	}
}

// enhance the clickable area for checkbox and radio elements
%form-element-enhanced-clickable-area {
	&:has(+ label) {
		&::after {
			content: "";
			width: calc(
				var(--db-form-element-dimensions) + #{to-rem($pxValue: 4)}
			);
			height: var(--db-form-element-dimensions);
			transform: translateX(#{to-rem($pxValue: 4)});
			position: absolute;
		}
	}
}
