@import "../../../css/partials.meta";
@import "tab-bar.variables";
@import "../../00-base/icons/icons.helpers";

@mixin tab() {
	& > input[type="radio"],
	& > * > input[type="radio"] {
		@content;
	}
}

.cmp-tab-bar {
	display: flex;
	flex-wrap: wrap;

	gap: 0.25rem;

	@include tab() {
		@include a11y-visually-hidden($partial: $partial);

		& + label[role="tab"] {
			cursor: pointer;
			display: flex;
			padding: 0.875rem $db-spacing-m;
			position: relative;
			z-index: 1;
			border-radius: 4px;
			align-items: center;

			&::before {
				margin-inline-start: -0.125rem;
			}
		}

		&:focus + label {
			outline: $tabBar-tab-focus-outline;
		}

		&:not(:checked) {
			& + label[role="tab"] {
				&:hover,
				&:focus {
					background-color: $tabBar-tab-hover-backgroundColor;
				}

				&:active {
					background-color: $tabBar-tab-pressed-backgroundColor;
				}
			}
		}

		&:disabled {
			& + label[role="tab"] {
				color: $db-color-cool-gray-400;

				&:hover,
				&:focus {
					background-color: unset;
				}
			}
		}

		&:checked {
			& + label[role="tab"] {
				font-weight: 700;

				& + section[role="tabpanel"] {
					display: initial;
				}

				// Puls
				&::after {
					background-color: $db-color-red-500;
					border-radius: 5px;
					content: "";
					display: block;
					height: 0.25rem;
					inset-block-end: 0;
					inset-inline: 0;
					position: absolute;
				}
			}
		}
	}

	label[role="tab"],
	section[role="tabpanel"] {
		background-color: $tabBar-tabSection--backgroundColor;
	}

	section[role="tabpanel"] {
		display: none;
		flex-basis: 100%;
		order: 1;
		margin-block-start: 0.125rem;
	}

	&[data-size="small"] {
		@include tab() {
			& + label[role="tab"] {
				padding-block: 0.625rem;
			}
		}
	}
}
