@import "../../../css/partials.meta";
@import "header.variables";
@import "../../../css/helpers/functions";
@import "../../../css/helpers/dividers";

.rea-header {
	background-color: $header---backgroundColor;
	box-shadow: $header---boxShadow;
	align-items: center;
	margin-bottom: $header---marginBottom;

	display: flex;
	gap: 1.5rem;

	min-block-size: $header---minHeight;
	padding-inline: 1.5rem;
	@media screen and (width > $db-break-the-header-max-width) {
		padding-block: 0.875rem;
	}

	// Divider only if there is a sitesearch and metanavigation
	&:has(.cmp-sitesearch):has(.cmp-metanavigation) {
		.cmp-sitesearch {
			&::before {
				@extend %header-divider;
				inset-inline-end: 0;
			}
		}
	}

	@media screen and (max-width: $db-break-the-header-max-width) {
		border-bottom: $header---borderBottom;
		position: relative;

		&:has(.cmp-mainnavigation > input[type="checkbox"][id]:not(:checked)) {
			.rea-meta {
				& > .elm-link,
				.cmp-metanavigation,
				.cmp-language-switcher,
				.cmp-dropdown {
					display: none;
				}
			}
		}

		&:has(.cmp-mainnavigation > input[type="checkbox"][id]:checked) {
			.rea-meta {
				--db-language-switcher---direction: -1;
				background-color: #fff;
				position: fixed;
				inset-inline-end: 0;
				inset-inline-start: 1.5rem;
				inset-block-start: unset;
				inset-block-end: 0;
				block-size: 3.5rem;
				z-index: $z-index-cmp-overflow-menu-overlay;
				border-top: 1px solid rgba(40, 45, 55, 0.2);
				border-bottom-left-radius: 4px;
				padding-inline: 1rem;

				.cmp-sitesearch {
					display: none;
				}

				.cmp-dropdown {
					& > menu {
						bottom: 100%;
						transform: translateY(-0.875rem);
					}
				}
			}
		}
	}
}
