@import "../../../css/partials.meta";
@import "sitesearch.variables";
@import "../../01-elements/form-elements";

.cmp-sitesearch {
	position: relative;
	display: flex;
	align-items: center;
	padding-inline-end: 0.75rem;

	@media screen and (max-width: $db-break-the-header-max-width) {
		margin-inline-start: auto;
		margin-inline-end: 2.25rem;

		&::before {
			display: none;
		}
	}

	.elm-input[type="search"] {
		@extend %form-element-solid;
		background-image: none;
		opacity: 0;
		width: 0;
		padding: 0;

		&:focus,
		&:not(:placeholder-shown) {
			opacity: 1;
			width: calc(#{$sitesearch-width--open} + 1rem);
			padding-inline-start: 1rem;
			padding-inline-end: 3rem;
			padding-top: 0;

			& + .elm-label {
				display: none;
			}

			& ~ .elm-button {
				display: flex;
			}
		}
	}

	.elm-label {
		padding: 0.5rem;
		border-radius: 4px;

		display: inline-block;

		& {
			@include icon(glyph(search), 24, "outline", $partial: $partial);
		}

		&::before {
			vertical-align: top;
		}

		.rea-meta & {
			@include is-icon-text-replace();
		}
	}

	.elm-button {
		@include is-icon-text-replace();

		display: none;
		position: absolute;
		padding: 0.5rem;
		inset-inline-end: 0.75rem;
		border: 0;

		& {
			@include icon(glyph(search), 24, "outline", $partial: $partial);
		}
	}

	.elm-label,
	.elm-button {
		background-color: rgba($db-color-cool-gray-700, 0);

		&:hover {
			background-color: rgba($db-color-cool-gray-700, 0.08);
		}

		&:active {
			background-color: rgba($db-color-cool-gray-700, 0.16);
		}
	}

	@media screen and (max-width: #{$sitesearch-form-full-maxWidth}) {
		&:focus-within,
		&:has(.elm-input[type="search"]:not(:placeholder-shown)) {
			left: to-rem($pxValue: 16);
			position: absolute;
			z-index: $z-index-rea-header-cmp-sitesearch-focus;

			.elm-input[type="search"] {
				width: calc(100vw - 5.5rem);
			}
		}
	}
}
