@import "../../../css/partials.meta";
@import "../overflow-menu/overflow-menu";
@import "dropdown.variables";

.cmp-dropdown {
	@extend .cmp-overflow-menu;

	// Usage within the account menu
	&.is-account {
		summary {
			@include icon(glyph(account), 24, "outline", $partial: $partial);

			@media screen and (max-width: $metanavigation-iconOnly--maxWidth) {
				// * TODO: possibly rework variable naming
				@include is-icon-text-replace();
			}
		}
	}

	menu,
	ul {
		background-color: $overflowMenu---backgroundColor;
		margin-block-start: initial;
		right: 0;
		transform: translateY(0.875rem);

		&::before {
			content: none;
		}
	}

	&[data-horizontal-position="opposite"] {
		menu,
		ul {
			right: unset;
		}
	}
}
