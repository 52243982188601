@import "meta.variables";

.rea-meta {
	display: flex;
	align-items: center;
	margin-inline-start: auto;
	gap: 0.5rem;

	ul,
	ol {
		margin: 0;
		padding: 0;
	}

	ul {
		list-style: none;
	}

	.rea-header & {
		order: 2;

		.elm-link {
			padding-bottom: to-rem($pxValue: 9);
			padding-top: to-rem($pxValue: 9);
		}
	}

	& > .elm-link {
		&[rel="account"] {
			margin-left: to-rem($pxValue: 4);

			& {
				@include is-icon-text-replace();
			}

			@media screen and (min-width: #{($header-srOnly-linkAccount-breakpoint + 1)}rem) {
				margin-left: to-rem($pxValue: 8);
			}
		}
	}

	.cmp-sitesearch:focus-within ~ .elm-link[rel="account"] {
		@include is-icon-text-replace();
	}
}
