@charset "utf-8";

@import "../../../css/partials.meta";
@import "input.variables";
@import "../form-elements";

.elm-input {
	@extend %form-element;

	color: $input---color;

	font-size: to-rem($pxValue: 16);
	height: to-rem($pxValue: 44);
	line-height: to-rem($pxValue: 24);
	max-width: 100%;

	width: 100%;

	padding-inline: $db-spacing-m;
	// No need to reposition the included value on hidden label
	&:not(:has(+ label[data-label-hidden="true"])) {
		padding-block: $db-spacing-s;
	}

	&[list] {
		&::-webkit-calendar-picker-indicator {
			margin-right: -1rem;
		}
	}

	&:not(:placeholder-shown) {
		padding-bottom: 0;
		// No need to reposition the included value on hidden label
		&:not(:has(+ label[data-label-hidden="true"])) {
			padding-top: to-rem($pxValue: 16);
		}
	}

	// * TODO: to get evaluated
	// display: inline-flex;
	// align-items: center;
	// justify-content: flex-start;
	// max-width: 100%;

	// TODO: Evaluate whether those could get moved to ../_form-elements.scss
	&:focus,
	&:hover {
		&:not(:disabled) {
			--formElement---borderColor: #{$db-color-cool-gray-400};
		}
	}

	// TODO: Evaluate whether those could get moved to ../_form-elements.scss
	&:disabled,
	fieldset:disabled & {
		&,
		& + .elm-label {
			// Decided against cursor: not-allowed, compare to e.g. https://phabricator.wikimedia.org/T121960
			opacity: 0.4;
		}
	}

	&[type="color"],
	&[type="date"],
	&[type="datetime-local"],
	&[type="email"],
	&[type="month"],
	&[type="number"],
	&[type="password"],
	:where(:not(.cmp-sitesearch)) > &[type="search"],
	&[type="tel"],
	&[type="text"],
	&[type="time"],
	&[type="url"],
	&[type="week"] {
		// TODO: Evaluate whether those could get moved to ../_form-elements.scss
		// Semitransparent is the default style
		@at-root :where(#{&}) {
			@extend %form-element-semitransparent;
		}

		// Floating labels
		&:not([type="date"]):not([type="datetime-local"]):not(
				[type="month"]
			):not([type="time"]):not([type="week"])
			+ .elm-label {
			// position: absolute;
			// left: calc(#{to-em($pxValue: 10)} - 1px);

			transition:
				opacity,
				transform 250ms;
			// Adopted by https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html
			@media (prefers-reduced-motion: reduce) {
				transition-duration: 0.01s !important;
			}
		}

		& + .elm-label {
			@extend %form-label;

			color: $db-color-cool-gray-500;
			display: block;
			font-size: to-rem($pxValue: 12);

			margin-bottom: -#{to-rem($pxValue: 18)};
			transform: translate($db-spacing-m, -#{to-rem($pxValue: 41)});

			& + output,
			& ~ .description {
				margin-top: 0;
			}
		}

		&[placeholder] {
			&:placeholder-shown {
				& + label {
					opacity: 0;
				}
			}
		}

		&::placeholder {
			color: $db-color-cool-gray-500;
		}

		// ### Style variations
		&[data-variant="semitransparent"] {
			@extend %form-element-semitransparent;
		}

		&[data-variant="white"] {
			@extend %form-element-white;
		}

		&[data-variant="solid"] {
			@extend %form-element-solid;
		}

		&[data-variant="outline"] {
			@extend %form-element-outline;
		}

		// Validation
		/* stylelint-disable order/order */
		@extend %form-element-validation;
		@extend %form-element-validation-programmatic;
		/* stylelint-enable order/order */
	}

	&:is(
			[type="email"],
			[type="password"],
			[type="search"],
			[type="tel"],
			[type="text"],
			[type="url"]
		)
		+ label
		+ output {
		@extend %form-element-counter;
	}

	&[type="search"] {
		background: var(
				--db-ic-search-24,
				url(#{$icons-path}functional/images/action/db_ic_search_24.svg)
			)
			no-repeat to-rem($pxValue: 6) to-rem($pxValue: 12);
		background-size: to-rem($pxValue: 19);
		padding-left: to-rem($pxValue: 32);

		:not(.cmp-sitesearch) > & {
			@extend %form-element-semitransparent;

			& + .elm-label {
				margin-left: to-rem($pxValue: 16);
			}
		}
	}
}
