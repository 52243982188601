@charset "utf-8";

@import "../../../css/partials.meta";
@import "radio.variables";
@import "../form-elements";

.elm-radio {
	--db-form-element-dimensions: var(
		--db-radio-dimensions,
		#{to-em($pxValue: 20)}
	);

	@extend %form-element-enhanced-clickable-area;

	appearance: none;

	background-color: rgba(255, 255, 255, 0.1);
	border: to-rem($pxValue: 1) solid $radio---border;
	border-radius: to-em($pxValue: 16);

	// * the basic, unchecked style
	display: inline-block;

	font-size: to-rem($pxValue: 16);
	height: var(--db-form-element-dimensions);

	transition: border-width 0.5s ease;

	width: var(--db-form-element-dimensions);

	&[type="radio"] {
		vertical-align: sub;
	}

	&:enabled {
		&:hover {
			&:not(:checked) {
				background-color: rgba(40, 45, 55, 0.14);
			}
		}

		&:active {
			background-color: rgba(40, 45, 55, 0.3);
		}
	}

	&:checked {
		border-width: to-em($pxValue: 6);
	}
	// * the invalid style using the :user-invalid pseudo class (and [aria-invalid="true"] equivalent, see #136 and #141)
	&:is(:user-invalid),
	&[aria-invalid="true"] {
		border-color: #c13e34;
	}
	// * TODO: never forget focus styling
	// &:focus::after {
	// content: "\0020\2190";
	// font-size: to-em($pxValue: 24);
	// line-height: 1;
	// vertical-align: -(to-em($pxValue: 2));
	// }

	& + label {
		@extend %form-label;

		padding-inline-start: $db-spacing-xs;
	}

	&:disabled {
		&,
		& + label {
			opacity: 0.5;
		}
	}
	// Adopted by https://www.heise.de/developer/artikel/a11y-Reduced-Motion-4356171.html
	@media (prefers-reduced-motion: reduce) {
		transition-duration: 0.01s !important;
	}
}
