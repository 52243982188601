@import "brand.variables";

.cmp-brand {
	font-weight: bold;
	display: flex;
	align-items: center;
	gap: 1rem;

	a,
	img {
		height: 1.5rem;
	}

	img {
		width: auto;
	}

	a {
		color: inherit;
		// Deactivate underline on site name being made clickable by added to the link as well
		text-decoration: none;
	}
}
