@import "../../../css/db-ui-core.variables";

@import "icons.variables";

// Potential TODO: usage of the standard filenames described by https://marketingportal.extranet.deutschebahn.com/marketingportal/Basiselemente/Icons/Funktionale-Icons-Architektur-und-Fahrzeuge

@font-face {
	font-display: block;
	font-family: "missing-icons";
	src:
		url("#{$icons-path}functional/fonts/icons-empty.woff2")
			format("woff2"),
		url("#{$icons-path}functional/fonts/icons-empty.woff")
			format("woff");
}

// DB UX Icons
@each $icon-style, $icon-sizes in $icon-font-families-personenverkehr {
	@each $icon-size, $icon-categories in $icon-sizes {
		@each $icon-category, $icon-font-unicodes in $icon-categories {
			@font-face {
				$icon-font-family: "icons-" + $icon-size + "-" + $icon-style;

				font-display: block;

				font-family: $icon-font-family;
				font-style: normal;
				font-weight: normal;
				src:
					url("#{$icons-path}functional/fonts/#{$icon-font-family+ "-" + $icon-category}.woff2")
						format("woff2"),
					url("#{$icons-path}functional/fonts/#{$icon-font-family+ "-" + $icon-category}.woff")
						format("woff");

				unicode-range: unquote($icon-font-unicodes);
			}
		}
	}
}
